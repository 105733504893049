import React, { Component } from 'react';
import Projectfull from "../../components/Projectfull";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import christmasfullImg from "../../images/projectsfull/bennychristmas-video.mp4";
import {GatsbySeo} from "gatsby-plugin-next-seo";
import {Helmet} from "react-helmet";

class Projects extends Component {
  render() {
    return (
      <div className="scroll-content">
        <Helmet>
          <meta charSet="utf-8" />
          <title>EntityOne Christmas animation</title>
        </Helmet>
        <GatsbySeo noindex={true} />
        <div className="page project">
          <Header />
          <Projectfull
              projectfullname="EntityOne Christmas animation"
              projectfulldescription="Benny the hamster, EntityOne’s self proclaimed mascot, makes an appearance in a Christmas animation video I made."
              projectclient="EntityOne"
              projectrole="Motion Design"
              projectdate="2019"
              projectfullimg={<video controls><source src={christmasfullImg} type="video/mp4" /></video>}
              projectnextlink="/projects/ipvdiergeneeskunde"
          />
          <Footer />
        </div>
      </div>
    );
  }
}

export default Projects;